import { iterate } from '@/utils/array'
import { HStack, useMediaQuery, useToken } from '@chakra-ui/react'
import { BiSolidStar, BiStar } from 'react-icons/bi'

type StarsProps = {
  count?: number
  note: number
}
export const Stars = ({ count = 5, note }: StarsProps) => {
  const [isLg] = useMediaQuery('(min-width: 1000px)')

  const color = useToken('colors', 'primary.500')
  const iconProps = { size: isLg ? 32 : 24, color }

  if (note > count) {
    throw Error('Note must be <= Count')
  }

  return (
    <HStack>
      {iterate(count).map(i =>
        i > note - 1 ? (
          <BiStar key={i} {...iconProps} />
        ) : (
          <BiSolidStar key={i} {...iconProps} />
        ),
      )}
    </HStack>
  )
}
