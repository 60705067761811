import { ImageFile } from '@/designSystem'
import { General } from '@/services/api/routes/general'
import Joi from 'joi'

export type GeneralFormValues = Omit<
  General,
  'reviewImage' | 'introductionImages'
> & {
  // Review Image
  oldReviewImage: string
  reviewImage: ImageFile | null

  // Introduction Images
  oldIntroductionImages: string[]
  oldDeletedIntroductionImages: string[]
  introductionImages: ImageFile[]
}

const getDefaultValues = (general?: General): GeneralFormValues => ({
  id: general?.id || -1,
  contactEmail: general?.contactEmail || '',
  contactPhone: general?.contactPhone || '',
  introductionTitle: general?.introductionTitle || '',
  introductionContent: general?.introductionContent || '',

  // Review Image
  oldReviewImage: general?.reviewImage || '',
  reviewImage: null,

  // Introduction Images
  oldIntroductionImages: general?.introductionImages || [],
  oldDeletedIntroductionImages: [],
  introductionImages: [],

  introductionVideo: general?.introductionVideo || '',
})

const schema = Joi.object<GeneralFormValues>({
  id: Joi.number(),
  contactEmail: Joi.string().required(),
  contactPhone: Joi.string().required(),
  introductionTitle: Joi.string().required(),
  introductionContent: Joi.string().required(),

  // Review Image
  oldReviewImage: Joi.string().optional().allow(''),
  reviewImage: Joi.object<ImageFile>({
    name: Joi.string(),
    data: Joi.any(),
    content: Joi.string(),
  })
    .optional()
    .allow(null),

  // Introduction Images
  oldIntroductionImages: Joi.array().items(Joi.string()),
  oldDeletedIntroductionImages: Joi.array().items(Joi.string()),
  introductionImages: Joi.array().items(
    Joi.object<ImageFile>({
      name: Joi.string(),
      data: Joi.any(),
      content: Joi.string(),
    }),
  ),

  introductionVideo: Joi.string().allow(''),
})

export const generalTabServices = {
  getDefaultValues,
  schema,
}
