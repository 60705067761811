import { FormTextInput, FormTextInputProps } from '@/designSystem'
import { useController } from 'react-hook-form'

export type FormTextInputControlledProps = { name: string } & Partial<
  Omit<FormTextInputProps, 'error'>
>

export const FormTextInputControlled = ({
  name,
  inputProps,
  ...props
}: FormTextInputControlledProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name })

  return (
    <FormTextInput
      error={error}
      inputProps={{
        onChange,
        value,
        ...inputProps,
      }}
      {...props}
    />
  )
}
