import {
  useQuery,
  useUpdateMutation,
} from '@supabase-cache-helpers/postgrest-react-query'
import { supabase } from '../..'
import { MutationOptions, QueryOptions } from '../../types'
import { General } from './entities'

const queryCol =
  'id, contactEmail, contactPhone, introductionTitle, introductionContent, introductionImages, introductionVideo, reviewImage'

export const useGeneral = (opt?: QueryOptions) =>
  useQuery<General>(
    supabase
      .from('general')
      .select(queryCol)
      .eq('siteId', process.env.REACT_APP_SITE_ID),
    opt,
  )

export const useUpdateGeneral = (opt?: MutationOptions<General>) =>
  useUpdateMutation(supabase.from('general'), ['id'], queryCol, opt)
