import {
  useUpload as useUploadRQ,
  useRemoveFiles as useRemoveFilesRQ,
  useFileUrl as useFileUrlRQ,
  useDirectoryFileUrls as useDirectoryFileUrlsRQ,
} from '@supabase-cache-helpers/storage-react-query'
import { supabase } from '..'
import { QueryOptions } from '@testing-library/react'
import { MutateOptions } from '@tanstack/react-query'
import { FileObject, StorageError } from '@supabase/storage-js'

const bucket = 'images'
const url = 'https://boinokgnlarvuhvvbmbi.supabase.co/storage/v1/object/public'

const buildFileName = ({
  fileName,
  path,
}: {
  fileName: string
  path?: string
}) => {
  const fileWithPath = path ? `${path}/${fileName}` : fileName
  return `${process.env.REACT_APP_SITE_ID}/${fileWithPath}`
}

export const useUpload = () =>
  useUploadRQ(supabase.storage.from(bucket), {
    buildFileName,
  })

export const useRemoveFiles = () => {
  const { mutate, mutateAsync, ...methods } = useRemoveFilesRQ(
    supabase.storage.from(bucket),
  )

  const build = (variables: string[], path?: string) =>
    variables.map(fileName => buildFileName({ fileName, path }))

  return {
    mutate: (
      variables: string[],
      path?: string,
      options?:
        | MutateOptions<FileObject[], StorageError, string[], unknown>
        | undefined,
    ) => mutate(build(variables, path), options),
    mutateAsync: (
      variables: string[],
      path?: string,
      options?:
        | MutateOptions<FileObject[], StorageError, string[], unknown>
        | undefined,
    ) => mutateAsync(build(variables, path), options),
    ...methods,
  }
}

export const useFileUrl = (filePath: string, opt?: QueryOptions) =>
  useFileUrlRQ(
    supabase.storage.from(bucket),
    `${process.env.REACT_APP_SITE_ID}/${filePath}`,
    'public',
    opt,
  )

export const useFileUrlBis = () => (filePath: string) =>
  `${url}/${bucket}/${process.env.REACT_APP_SITE_ID}/${filePath}`

export const useDirectoryFileUrls = (
  directoryPath: string,
  opt?: QueryOptions,
) =>
  useDirectoryFileUrlsRQ(
    supabase.storage.from(bucket),
    `${process.env.REACT_APP_SITE_ID}/${directoryPath}`,
    'public',
    opt,
  )
