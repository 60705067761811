import { HStack, VStack } from '@chakra-ui/react'
import { BookingForm, Section, TranslateText } from '@/components'
import { useTranslate } from '@/services/i18n'
import { useGeneral } from '@/services/api/routes/general'
import { Helmet } from 'react-helmet-async'

export const Booking = () => {
  const t = useTranslate(undefined, {
    keyPrefix: `booking.${process.env.REACT_APP_SITE_TYPE.toLowerCase()}`,
  })
  const { data: generalData } = useGeneral()

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t('subtext', { cellphone: generalData?.[0].contactPhone })}
        />
      </Helmet>

      <Section
        py={12}
        title={t('title')}
        subText={
          <TranslateText
            t={t}
            i18nKey="subtext"
            values={{ cellphone: generalData?.[0].contactPhone }}
          />
        }>
        <HStack flex={1} w="full">
          <VStack flex={1} spacing={2}>
            <BookingForm />
          </VStack>
        </HStack>
      </Section>
    </>
  )
}
