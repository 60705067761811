import { useFileUrl } from '@/services/api/utils/file'
import {
  Stack,
  Box,
  Heading,
  Text,
  Image,
  HStack,
  Tag,
  VStack,
  Center,
  useColorModeValue,
  Skeleton,
} from '@chakra-ui/react'

export type ServiceItemProps = {
  title?: string
  description?: string
  price?: number
  image?: string
  reversed?: boolean
}

export const ServiceItem = ({
  title,
  description,
  image,
  price,
  reversed = false,
}: ServiceItemProps) => {
  const { data, isLoading } = useFileUrl(image || '', { enabled: !!image })
  const bgImageColor = useColorModeValue('gray.100', 'gray.800')
  const descColor = useColorModeValue('gray.800', 'white')
  return (
    <Stack
      w="full"
      align="center"
      spacing={{ base: 8, md: 12, lg: 20 }}
      direction={{ base: 'column', md: reversed ? 'row-reverse' : 'row' }}>
      {(title || description || price) && (
        <VStack flex={1} w="full" align="stretch" spacing={{ base: 5, md: 10 }}>
          {title && (
            <Heading color="primary.400" size="lg">
              {title}
            </Heading>
          )}
          {description && <Text color={descColor}>{description}</Text>}
          {price && (
            <HStack>
              <Tag size="lg" color="primary.500">
                {price} €
              </Tag>
            </HStack>
          )}
        </VStack>
      )}
      {image && (
        <Center flex={1} position="relative" w="full">
          <Box
            position="relative"
            w="full"
            height={{ base: 'auto', md: '300px' }}
            rounded="2xl"
            boxShadow="2xl"
            overflow="hidden">
            {isLoading ? (
              <Skeleton w="full" h="full" />
            ) : (
              <Image
                alt="image"
                fit="cover"
                align="center"
                w="full"
                h="full"
                bgColor={bgImageColor}
                src={data}
              />
            )}
          </Box>
        </Center>
      )}
    </Stack>
  )
}
