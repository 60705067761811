import { supabase } from '@/services/api'
import { buildContext } from '@/services/buildContext'
import {
  AuthError,
  AuthTokenResponse,
  SignInWithPasswordCredentials,
  User,
} from '@supabase/supabase-js'
import { PropsWithChildren, useEffect, useState } from 'react'

type AuthContextProps = {
  login: (
    credentials: SignInWithPasswordCredentials,
  ) => Promise<AuthTokenResponse>
  signOut: () => Promise<{
    error: AuthError | null
  }>
  auth: boolean
  user?: User
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [_, Provider, useAuth] = buildContext<AuthContextProps>('Auth')

export { useAuth }

const login = (credentials: SignInWithPasswordCredentials) =>
  supabase.auth.signInWithPassword(credentials)

const signOut = () => supabase.auth.signOut()

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User>()
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const getUser = async () => {
      const { data } = await supabase.auth.getUser()
      setUser(data.user || undefined)
      setLoading(false)
    }
    getUser()

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session?.user)
        setAuth(true)
      } else if (event === 'SIGNED_OUT') {
        setUser(undefined)
        setAuth(false)
      }
    })
    return () => {
      data.subscription.unsubscribe()
    }
  }, [])

  return (
    <Provider value={{ auth, user, login, signOut }}>
      {!loading && children}
    </Provider>
  )
}
