import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@/services/theme'
import { NavItem, Navbar, Footer } from '@/designSystem'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useInitLanguage, useTranslate } from '@/services/i18n'
import { useMemo } from 'react'
import { routes } from './navigation'
import { ScrollToTop } from './navigation/ScrollToTop'
import { AuthProvider, useAuth } from './components/AuthProvider/AuthProvider'

import NiceModal from '@ebay/nice-modal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { NotFound } from './pages/NotFound/NotFound'
import { Admin, Booking, Home, Login, Services } from './pages'
import { HelmetProvider } from 'react-helmet-async'

const Component = () => {
  useInitLanguage()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 10 * 60 * 1000,
        staleTime: 10 * 60 * 1000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  })

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NiceModal.Provider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </NiceModal.Provider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  )
}

const App = () => {
  const t = useTranslate(undefined, { keyPrefix: 'navbar' })
  const { auth } = useAuth()

  const siteType = process.env.REACT_APP_SITE_TYPE.toLowerCase()

  const navItems: NavItem[] = useMemo(
    () => [
      {
        label: t('home'),
        href: routes.home,
      },
      {
        label: t(`services.${siteType}`),
        href: routes.services,
      },
      {
        label: t(`booking.${siteType}`),
        href: routes.booking,
      },
    ],
    [t, siteType],
  )

  return (
    <BrowserRouter>
      <Navbar
        title={process.env.REACT_APP_SITE_NAME}
        navItems={navItems}
        homeHref={routes.home}
      />
      <Routes>
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.services} element={<Services />} />
        <Route path={routes.booking} element={<Booking />} />
        <Route path={routes.login} element={<Login />} />
        {auth && <Route path={routes.admin} element={<Admin />} />}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  )
}

export default Component
