import { Textarea, TextareaProps } from '@chakra-ui/react'
import { FormContainer } from '../FormContainer/FormContainer'
import { FormContainerProps } from '../FormContainer/FormContainer'

export type FormTextAreaInputProps = FormContainerProps & {
  textAreaInputProps?: TextareaProps
}

export const FormTextAreaInput = ({
  textAreaInputProps,
  ...props
}: FormTextAreaInputProps) => (
  <FormContainer {...props}>
    <Textarea {...textAreaInputProps} rows={6} />
  </FormContainer>
)
