import i18next from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import { resources } from '../resources'
import { getDefaultNS, getFallbackLanguage } from '../utils'
import LanguageDetector from 'i18next-browser-languagedetector'

export const useInitLanguage = () => {
  i18next
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      compatibilityJSON: 'v3',
      resources,
      fallbackLng: getFallbackLanguage(),
      defaultNS: getDefaultNS('global'),
      interpolation: {
        escapeValue: false,
      },
    })
}
