import { FormTextAreaInput, FormTextAreaInputProps } from '@/designSystem'
import { useController } from 'react-hook-form'

export type FormTextAreaInputControlledProps = {
  name: string
} & Partial<Omit<FormTextAreaInputProps, 'error'>>

export const FormTextAreaInputControlled = ({
  name,
  textAreaInputProps,
  ...props
}: FormTextAreaInputControlledProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name })

  return (
    <FormTextAreaInput
      error={error}
      textAreaInputProps={{
        onChange,
        value,
        ...textAreaInputProps,
      }}
      {...props}
    />
  )
}
