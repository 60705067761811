import { HStack } from '@chakra-ui/react'
import { FormContainer } from '../FormContainer/FormContainer'
import { FormContainerProps } from '../FormContainer/FormContainer'
import { useFilePicker } from '@/hooks/useFilePicker'
import { FileSizeValidator } from 'use-file-picker/validators'
import { SelectedFiles } from 'use-file-picker/dist/interfaces'
import { v4 as uuid } from 'uuid'
import { dataURLtoBlob } from '@/utils/file'
import { ImagePreview } from '@/designSystem/ImagePreview/ImagePreview'
import { useDirectoryFileUrls } from '@/services/api/utils/file'

export type FormFileManyInputProps = FormContainerProps & {
  oldValue?: string[]
  value?: ImageFile[]
  onChange: (_: ImageFile[]) => void
  onChangeOldValue: (_: string[]) => void
  onDeleteOldValue: (_: string) => void
  onError?: (errorName: string) => void
  directoryPath: string
}

type ImageFile = {
  name: string
  data: Blob
  content: string
}

export const FormFileManyInput = ({
  onChange,
  onChangeOldValue,
  onDeleteOldValue,
  onError,
  value = [],
  oldValue,
  directoryPath,
  ...props
}: FormFileManyInputProps) => {
  const onFilesSelected = async (data: SelectedFiles<string>) => {
    const files = await Promise.all(
      data.filesContent.map(async ({ content, name: fileName }) => {
        const [name, ext] = fileName.split('.')
        return {
          data: await dataURLtoBlob(content),
          name: `${name}_${uuid()}.${ext}`,
          content,
        }
      }),
    )
    onChange([...value, ...files])
  }

  const { openFilePicker } = useFilePicker({
    accept: ['.jpeg', '.jpg', '.png'],
    multiple: true,
    readAs: 'DataURL',
    validators: [new FileSizeValidator({ maxFileSize: 2 * 1000000 })],
    onFilesSuccessfullySelected: onFilesSelected,
    onFilesRejected: d => onError?.(d.errors[0]?.name),
  })

  const { data: images } = useDirectoryFileUrls(directoryPath, {
    enabled: !!oldValue?.length,
  })

  const oldImages = images?.filter(({ name }) => oldValue?.includes(name))

  const previews = [...(oldImages || []), ...value]

  return (
    <FormContainer {...props}>
      <HStack flexWrap="wrap">
        {previews.map((preview, index) => {
          if (isImageFile(preview)) {
            return (
              <ImagePreview
                key={index}
                src={preview}
                onDelete={() =>
                  onChange(value.filter(v => v.name !== preview.name))
                }
              />
            )
          }

          return (
            <ImagePreview
              key={index}
              src={preview.url}
              onDelete={() => {
                onDeleteOldValue(preview.name)
                onChangeOldValue(
                  oldValue
                    ? oldValue.filter(name => name !== preview.name)
                    : [],
                )
              }}
            />
          )
        })}
        <ImagePreview onClick={openFilePicker} />
      </HStack>
    </FormContainer>
  )
}

const isImageFile = (object: any): object is ImageFile =>
  object.name && object.data && object.content
