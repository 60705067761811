import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react'
import { useTranslateError } from '@/hooks/useTranslateApiError'
import { PropsWithChildren } from 'react'
import { FieldError } from 'react-hook-form'

export type FormContainerProps = {
  error?: FieldError
  title?: string
  helperText?: string
  isRequired?: boolean
}

export const FormContainer = ({
  error,
  children,
  title,
  helperText,
  isRequired,
}: PropsWithChildren<FormContainerProps>) => {
  const isError = !!error
  const tError = useTranslateError()

  return (
    <FormControl isInvalid={isError} isRequired={isRequired}>
      {title && <FormLabel>{title}</FormLabel>}
      {children}
      {!isError ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>
          {tError(error.type) || error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}
