import { Review } from '@/services/api/routes'
import Joi from 'joi'

export type ReviewFormValues = Review

const getDefaultValues = (review?: Review): ReviewFormValues => ({
  id: review?.id || -1,
  author: review?.author || '',
  content: review?.content || '',
  note: review?.note || 0,
})

const schema = Joi.object<ReviewFormValues>({
  id: Joi.number(),
  author: Joi.string().required(),
  content: Joi.string().required(),
  note: Joi.number().required(),
})

export const reviewFormServices = {
  getDefaultValues,
  schema,
}
