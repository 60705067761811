import {
  FormFileInputControlled,
  FormFileManyInputControlled,
  FormTextAreaInputControlled,
  FormTextInputControlled,
} from '@/components'
import { useGeneral, useUpdateGeneral } from '@/services/api/routes/general'
import { useTranslate } from '@/services/i18n'
import { Button, Heading, VStack } from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  GeneralFormValues,
  generalTabServices as services,
} from './GeneralTabServices'
import { joiResolver } from '@hookform/resolvers/joi'
import { useEffect } from 'react'
import { useHandleSuccess } from '@/hooks/useHandleSuccess'
import { useHandleError } from '@/hooks/useHandleError'
import { isNil } from 'remeda'
import { ImageFile } from '@/designSystem'
import { isNilOrEmpty } from '@/utils'
import { useHandleFileUpload } from '@/hooks/useHandleFileUpload'

const { getDefaultValues, schema } = services

export const GeneralTab = () => {
  const tGlobal = useTranslate()
  const t = useTranslate(undefined, { keyPrefix: 'admin.tabs.general' })
  const handleSuccess = useHandleSuccess()
  const handleError = useHandleError()

  const { data: general, refetch } = useGeneral()

  const {
    uploadAsync,
    removeAsync,
    isLoading: isLoadingUpload,
  } = useHandleFileUpload()

  const methods = useForm<GeneralFormValues>({
    defaultValues: getDefaultValues(general?.[0]),
    mode: 'onSubmit',
    resolver: joiResolver(schema),
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = methods

  const { mutate, isLoading } = useUpdateGeneral({
    onSuccess: () => {
      handleSuccess({ title: tGlobal('toasts.edited') })
      refetch()
    },
    onError: () => handleError(),
  })

  useEffect(() => {
    reset(getDefaultValues(general?.[0]))
  }, [reset, general])

  const onSubmit = async ({
    reviewImage,
    oldReviewImage,

    introductionImages,
    oldDeletedIntroductionImages,
    oldIntroductionImages,
    ...data
  }: GeneralFormValues) => {
    // introductionImages
    const introductionImagesDirectory = 'introductionImages'
    if (!!introductionImages.length) {
      await uploadAsync({
        files: introductionImages,
        path: introductionImagesDirectory,
      })
    }

    if (!!oldDeletedIntroductionImages.length) {
      removeAsync(oldDeletedIntroductionImages, introductionImagesDirectory)
    }

    const files = [reviewImage].filter((f): f is ImageFile => !isNil(f))

    const oldFiles = [...[reviewImage ? oldReviewImage : undefined]].filter(
      (of): of is string => !isNilOrEmpty(of),
    )

    if (!!files.length) {
      await uploadAsync({ files })

      if (oldFiles.length) {
        await removeAsync(oldFiles)
      }
    }

    mutate({
      reviewImage: reviewImage?.name,
      introductionImages: [
        ...oldIntroductionImages.filter(
          old => !oldDeletedIntroductionImages.includes(old),
        ),
        ...introductionImages.map(({ name }) => name),
      ],
      ...data,
    })
  }

  return (
    <FormProvider {...methods}>
      <VStack pt={6} align="stretch" gap={10}>
        {/* COORDINATES */}
        <VStack align="stretch" gap={6}>
          <Heading>{t('coordinates.title')}</Heading>
          <FormTextInputControlled name="id" inputProps={{ type: 'hidden' }} />
          <FormTextInputControlled
            name="contactEmail"
            isRequired
            title={t('coordinates.form.email.title')}
            helperText={t('coordinates.form.email.helperText')}
          />
          <FormTextInputControlled
            name="contactPhone"
            isRequired
            title={t('coordinates.form.phone.title')}
            helperText={t('coordinates.form.phone.helperText')}
          />
        </VStack>

        {/* INTRODUCTION */}
        <VStack align="stretch" gap={6}>
          <Heading>{t('introduction.title')}</Heading>
          <FormTextInputControlled
            name="introductionTitle"
            isRequired
            title={t('introduction.form.title')}
          />
          <FormTextAreaInputControlled
            name="introductionContent"
            isRequired
            title={t('introduction.form.content')}
          />
          <FormTextInputControlled
            name="introductionVideo"
            title={t('introduction.form.video.title')}
            helperText={t('introduction.form.video.helperText')}
          />
          <FormFileManyInputControlled
            directoryPath="introductionImages"
            name="introductionImages"
            oldName="oldIntroductionImages"
            deletedName="oldDeletedIntroductionImages"
          />
        </VStack>

        {/* REVIEW */}
        <VStack align="stretch" gap={6}>
          <Heading>{t('review.title')}</Heading>
          <FormFileInputControlled
            title={t('review.form.image')}
            name="reviewImage"
            oldName="oldReviewImage"
            isRequired
          />
        </VStack>
        <Button
          isDisabled={!isDirty}
          isLoading={isLoading || isLoadingUpload}
          colorScheme="primary"
          onClick={handleSubmit(onSubmit)}>
          {tGlobal('submit')}
        </Button>
      </VStack>
    </FormProvider>
  )
}
