import {
  FormFileInputControlled,
  FormTextAreaInputControlled,
  FormTextInputControlled,
} from '@/components'
import { useTranslate } from '@/services/i18n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  ServiceFormValues,
  serviceFormServices as services,
} from './ServiceForm.services'
import { useEffect } from 'react'
import { Service } from '@/services/api/routes'
import { joiResolver } from '@hookform/resolvers/joi'
import { UpdateModal } from '@/components/Modals/UpdateModal/UpdateModal'
import { useModal } from '@ebay/nice-modal-react'
import { useManageService } from '../../ServicesTabServices'
import { UpdateModalContent } from '@/components/Modals/UpdateModal/UpdateModalContent'
import { isNil } from 'remeda'
import { useHandleFileUpload } from '@/hooks/useHandleFileUpload'

const { getDefaultValues, schema } = services

export type ServiceFormProps = { service?: Service }

export const ServiceForm = ({ service }: ServiceFormProps) => {
  const t = useTranslate(undefined, { keyPrefix: 'admin.tabs.services.form' })
  const modal = useModal(UpdateModal)

  const { createAsync, updateAsync, isLoading } = useManageService()
  const {
    uploadAsync,
    removeAsync,
    isLoading: isLoadingUpload,
  } = useHandleFileUpload()

  const methods = useForm<ServiceFormValues>({
    defaultValues: getDefaultValues(service),
    mode: 'onSubmit',
    resolver: joiResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods

  useEffect(() => {
    reset(getDefaultValues(service))
  }, [reset, service])

  const onSubmit = async ({ id, oldImage, image, ...d }: ServiceFormValues) => {
    if (!isNil(image)) {
      await uploadAsync({ files: [image] })

      if (oldImage) {
        await removeAsync([oldImage])
      }
    }

    if (id > -1) {
      await updateAsync({ id, image: image?.name, ...d })
    } else {
      await createAsync([{ image: image?.name, ...d }])
    }
    modal.hide()
  }

  return (
    <FormProvider {...methods}>
      <UpdateModalContent
        isDisabled={!isDirty}
        isLoading={isLoading || isLoadingUpload}
        onSubmit={handleSubmit(onSubmit)}
        form={
          <>
            <FormTextInputControlled
              name="id"
              inputProps={{ type: 'hidden' }}
            />
            {process.env.REACT_APP_SITE_TYPE === 'MASSAGE' && (
              <>
                <FormTextInputControlled
                  title={t('title')}
                  name="title"
                  isRequired
                />
                <FormTextAreaInputControlled
                  title={t('description')}
                  name="description"
                  isRequired
                />
                <FormTextInputControlled
                  title={t('price')}
                  inputProps={{ type: 'number' }}
                  name="price"
                  isRequired
                />
              </>
            )}
            <FormTextInputControlled
              title={t('order')}
              inputProps={{ type: 'number' }}
              name="order"
              isRequired
            />
            <FormFileInputControlled
              title={t('image')}
              name="image"
              oldName="oldImage"
              isRequired
            />
          </>
        }
        onCancel={modal.hide}
      />
    </FormProvider>
  )
}
