import { useTranslate } from '@/services/i18n'
import { useToast } from '@chakra-ui/react'

type HandleErrorProps = {
  title?: string
}

export const useHandleError = () => {
  const toast = useToast()
  const tGlobal = useTranslate()

  return ({ title }: HandleErrorProps = {}) =>
    toast({
      colorScheme: 'error',
      title: title || tGlobal('toasts.error'),
      status: 'error',
    })
}
