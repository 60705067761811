import { Button, Center, HStack, Heading, Text, VStack } from '@chakra-ui/react'
import { Section, TestimonialsCarousel, VideoSection } from '@/components'
import { useTranslate } from '@/services/i18n'
import { useNavigate } from 'react-router-dom'
import { routes } from '@/navigation'
import { useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGeneral } from '@/services/api/routes/general'
import { useDirectoryFileUrls } from '@/services/api/utils/file'

export const Home = () => {
  const t = useTranslate(undefined, { keyPrefix: 'home' })
  const navigate = useNavigate()
  const tGlobal = useTranslate()
  const aboutUsRef = useRef<HTMLDivElement>(null)
  const { data } = useGeneral()
  const generalData = data?.[0]

  const welcomeTitle =
    process.env.REACT_APP_SITE_TYPE === 'MASSAGE'
      ? t('title', { companyName: process.env.REACT_APP_SITE_NAME })
      : process.env.REACT_APP_SITE_NAME

  const { data: images } = useDirectoryFileUrls('introductionImages', {
    enabled: !!generalData?.introductionImages.length,
  })

  const executeScroll = () =>
    aboutUsRef.current?.scrollIntoView({ behavior: 'smooth' })

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={`${generalData?.introductionTitle} ${generalData?.introductionContent}`}
        />
      </Helmet>

      {generalData?.introductionVideo ? (
        <VideoSection url={generalData?.introductionVideo}>
          <Center position="absolute" top={0} left={0} w="full" h="full">
            <VStack spacing={6} mx={6}>
              <Heading color="white" size="2xl" textAlign="center">
                {welcomeTitle}
              </Heading>
              <Button colorScheme="primary" onClick={executeScroll}>
                {tGlobal('discover')}
              </Button>
            </VStack>
          </Center>
        </VideoSection>
      ) : (
        <Section h="calc(100vh - 72px)" images={images?.map(({ url }) => url)}>
          <VStack spacing={6} mx={6}>
            <Heading color="white" size="2xl" textAlign="center">
              {welcomeTitle}
            </Heading>
            <Button colorScheme="primary" onClick={executeScroll}>
              {tGlobal('discover')}
            </Button>
          </VStack>
        </Section>
      )}
      <Section ref={aboutUsRef} h="50vh">
        <VStack spacing={6} mx={6}>
          <Heading size="xl" textAlign="center">
            {generalData?.introductionTitle}
          </Heading>
          <Text textAlign="center">{generalData?.introductionContent}</Text>
          <HStack>
            <Button
              colorScheme="primary"
              onClick={() => navigate(routes.services)}>
              {tGlobal(process.env.REACT_APP_SITE_TYPE.toLowerCase())}
            </Button>
            <Button
              colorScheme="secondary"
              onClick={() => navigate(routes.booking)}>
              {tGlobal('book')}
            </Button>
          </HStack>
        </VStack>
      </Section>
      <TestimonialsCarousel />
    </>
  )
}
