import { useState } from 'react'
import { supabase } from '../..'
import { SendEmail } from './entities'

type QueryOpt = Partial<{
  onSuccess: () => void
  onError: () => void
}>

export const useSendEmail = ({ onSuccess, onError }: QueryOpt = {}) => {
  const [isLoading, setIsLoading] = useState(false)

  const mutateAsync = async (data: Omit<SendEmail, 'sender' | 'subject'>) => {
    setIsLoading(true)
    const { error } = await supabase.functions.invoke('mailerV2', {
      body: {
        sender: {
          name: process.env.REACT_APP_SITE_NAME,
          email: process.env.REACT_APP_EMAIL_SENDER,
        },
        subject: process.env.REACT_APP_EMAIL_SUBJECT,
        ...data,
      },
    })

    if (error) {
      setIsLoading(false)
      onError?.()
      return
    }

    setIsLoading(false)
    onSuccess?.()
  }

  return { isLoading, mutateAsync }
}
