import { Input, InputProps } from '@chakra-ui/react'
import { FormContainer } from '../FormContainer/FormContainer'
import { FormContainerProps } from '../FormContainer/FormContainer'

export type FormTextInputProps = FormContainerProps & {
  inputProps?: InputProps
}

export const FormTextInput = ({ inputProps, ...props }: FormTextInputProps) => (
  <FormContainer {...props}>
    <Input {...inputProps} />
  </FormContainer>
)
