import { useTranslate } from '@/services/i18n'
import { Section } from '@/components'
import { Tabs } from '@/components/Tabs/Tabs'
import { ServicesTab } from './tabs/servicesTab/ServicesTab'
import { ReviewsTab } from './tabs/reviewsTab/ReviewsTab'
import { GeneralTab } from './tabs/generalTab/GeneralTab'

export const Admin = () => {
  const t = useTranslate(undefined, { keyPrefix: 'admin' })

  const tabs = [
    {
      title: t('tabs.general.title'),
      content: <GeneralTab />,
    },
    {
      title: t('tabs.services.title'),
      content: <ServicesTab />,
    },
    {
      title: t('tabs.reviews.title'),
      content: <ReviewsTab />,
    },
  ]

  return (
    <Section py={12}>
      <Tabs items={tabs} />
    </Section>
  )
}
