import { Center } from '@chakra-ui/react'
import { PropsWithChildren, forwardRef } from 'react'

type VideoSectionProps = {
  url: string
}

export const VideoSection = forwardRef<
  HTMLDivElement,
  PropsWithChildren<VideoSectionProps>
>(({ url, children }: PropsWithChildren<VideoSectionProps>, ref) => (
  <Center ref={ref} w="full" pos="relative" pb={'56.25%'}>
    <iframe
      style={{ position: 'absolute', top: 0, left: 0 }}
      width="100%"
      height="100%"
      src={`${url}?autoplay=1&loop=1&controls=0&mute=1`}
      title="YouTube video player"
      frameBorder="0"
      allow="autoplay"></iframe>
    {children}
  </Center>
))
