import { FormContainer } from '../FormContainer/FormContainer'
import { FormContainerProps } from '../FormContainer/FormContainer'
import { useFilePicker } from '@/hooks/useFilePicker'
import { FileSizeValidator } from 'use-file-picker/validators'
import { useFileUrl } from '@/services/api/utils/file'
import { SelectedFiles } from 'use-file-picker/dist/interfaces'
import { v4 as uuid } from 'uuid'
import { isEmpty } from 'remeda'
import { dataURLtoBlob } from '@/utils/file'
import {
  ImageFile,
  ImagePreview,
} from '@/designSystem/ImagePreview/ImagePreview'

export type FormFileInputProps = FormContainerProps & {
  oldValue: string
  value: ImageFile | null
  onChange: (_: ImageFile) => void
  onError?: (errorName: string) => void
}

export const FormFileInput = ({
  onChange,
  onError,
  value,
  oldValue,
  ...props
}: FormFileInputProps) => {
  const onFilesSelected = async (data: SelectedFiles<string>) => {
    const { content, name: fileName } = data.filesContent?.[0]
    const [name, ext] = fileName.split('.')

    onChange({
      data: await dataURLtoBlob(content),
      content,
      name: `${name}_${uuid()}.${ext}`,
    })
  }

  const { openFilePicker } = useFilePicker({
    accept: ['.jpeg', '.jpg', '.png'],
    multiple: false,
    readAs: 'DataURL',
    validators: [new FileSizeValidator({ maxFileSize: 2 * 1000000 })],
    onFilesSuccessfullySelected: onFilesSelected,
    onFilesRejected: d => onError?.(d.errors[0]?.name),
  })

  const { data } = useFileUrl(oldValue || '', {
    enabled: !isEmpty(oldValue),
  })

  const preview = value?.content || data

  return (
    <FormContainer {...props}>
      <ImagePreview src={preview} onClick={openFilePicker} />
    </FormContainer>
  )
}
