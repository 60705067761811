import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useTranslate } from '@/services/i18n'
import { FormProvider, useForm } from 'react-hook-form'
import { useAuth } from '@/components/AuthProvider/AuthProvider'
import { joiResolver } from '@hookform/resolvers/joi'
import { LoginFormValues, loginServices as services } from './Login.services'
import { FormTextInputControlled } from '@/components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '@/navigation'
import { useTranslateError } from '@/hooks/useTranslateApiError'

const { getDefaultValues, schema } = services

export const Login = () => {
  const t = useTranslate(undefined, { keyPrefix: 'login' })
  const tError = useTranslateError()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigation = useNavigate()

  const { login } = useAuth()

  const methods = useForm<LoginFormValues>({
    defaultValues: getDefaultValues(),
    mode: 'onSubmit',
    resolver: joiResolver(schema),
  })

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const onSubmit = async (values: LoginFormValues) => {
    setIsLoading(true)

    login(values)
      .then(({ error }) => {
        if (error) {
          setError('root', { type: 'AuthApiError' })
          return
        }
        navigation(routes.admin)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} minW={'md'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>{t('title')}</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            {errors.root && (
              <Text color="error.500">
                {tError(errors.root.type as string)}
              </Text>
            )}
            <FormProvider {...methods}>
              <FormTextInputControlled
                title={t('email')}
                name="email"
                isRequired
              />
              <FormTextInputControlled
                title={t('password')}
                name="password"
                inputProps={{ type: 'password' }}
                isRequired
              />
            </FormProvider>
            <Stack spacing={10}>
              <Button
                colorScheme="primary"
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}>
                {t('login')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}
