import Joi from 'joi'

export type BookingFormValues = {
  name: string
  email: string
  cellphone: string
  date?: Date
  note: string
}

const getDefaultValues = (): BookingFormValues => ({
  name: '',
  email: '',
  cellphone: '',
  date: undefined,
  note: '',
})

const phoneRegex = /^(0|\+33|\+262)[1-9](\d{2}){4}$/

const schema = Joi.object<BookingFormValues>({
  name: Joi.string().required(),
  email: Joi.string().email({ tlds: { allow: false } }),
  cellphone: Joi.string().regex(phoneRegex).required(),
  date: Joi.date().required(),
  note: Joi.string().optional(),
})

export const bookingFormServices = {
  getDefaultValues,
  schema,
}
