import { Grid, VStack } from '@chakra-ui/react'
import { Section } from '@/components'
import { ServiceItem } from './components/ServiceItem/ServiceItem'
import { useTranslate } from '@/services/i18n'
import { useServices } from '@/services/api/routes'
import { Loader } from '@/designSystem'
import { Helmet } from 'react-helmet-async'
import { PropsWithChildren } from 'react'

export const Services = () => {
  const siteType = process.env.REACT_APP_SITE_TYPE
  const t = useTranslate(undefined, {
    keyPrefix: `services.${siteType.toLowerCase()}`,
  })

  const { data, isLoading } = useServices()

  if (isLoading) return <Loader />

  return (
    <>
      <Helmet>
        <meta name="description" content={t('subText')} />
      </Helmet>
      <Section py={12} title={t('title')} subText={t('subText')}>
        <Container siteType={siteType}>
          {data
            ?.sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <ServiceItem
                key={item.order}
                reversed={index % 2 !== 0}
                {...item}
              />
            ))}
        </Container>
      </Section>
    </>
  )
}

const Container = ({ children, siteType }: PropsWithChildren<any>) => {
  if (siteType === 'MASSAGE')
    return (
      <VStack spacing={{ base: 16, md: 32 }} w="full" align="stretch">
        {children}
      </VStack>
    )

  return (
    <Grid gap={12} gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}>
      {children}
    </Grid>
  )
}
