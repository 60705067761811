import {
  useQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} from '@supabase-cache-helpers/postgrest-react-query'
import { supabase } from '../..'
import { Service } from './entities'
import { MutationOptions, QueryOptions } from '../../types'

const queryCol = 'id, title, description, price, image, order'
const mutationCol = 'title, description, price, image, order'

export const useServices = (opt?: QueryOptions) =>
  useQuery<Service[]>(
    supabase
      .from('services')
      .select(queryCol)
      .eq('siteId', process.env.REACT_APP_SITE_ID),
    opt,
  )

export const useService = (id?: number, opt?: QueryOptions) =>
  useQuery<Service>(
    supabase.from('services').select(queryCol).eq('id', id),
    opt,
  )

export const useAddServices = (opt?: MutationOptions<Service[]>) =>
  useInsertMutation(supabase.from('services'), ['id'], mutationCol, opt)

export const useUpdateService = (opt?: MutationOptions<Service>) =>
  useUpdateMutation(supabase.from('services'), ['id'], queryCol, opt)

export const useDeleteService = (opt?: MutationOptions<Service>) =>
  useDeleteMutation(supabase.from('services'), ['id'], mutationCol, opt)
