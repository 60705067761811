import { useTranslate } from '@/services/i18n'
import { Link, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'

export const RecatpchaBadge = () => {
  const t = useTranslate()
  return (
    <Text fontSize="sm">
      <Trans t={t} i18nKey="recaptcha">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          color="primary.500"
          href="https://policies.google.com/privacy">
          {t('privacyPolicy')}
        </Link>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          color="primary.500"
          href="https://policies.google.com/terms">
          {t('termsOfService')}
        </Link>
      </Trans>
    </Text>
  )
}
