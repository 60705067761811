import { useFileUrl } from '@/services/api/utils/file'
import { useTranslate } from '@/services/i18n'
import { CloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react'

const height = 32
const width = 56

const isUri = (string: string) => {
  try {
    new URL(string)
  } catch (_) {
    return false
  }
  return true
}

const getPreview = (src?: ImageFile | string | undefined) => {
  if (typeof src === 'string' && isUri(src)) return src

  if (typeof src !== 'string') return src?.content
}

export type ImageFile = {
  name: string
  data: Blob
  content: string
}

export type ImagePreviewProps = {
  src?: ImageFile | string | undefined
  onClick?: () => void
  onDelete?: () => void
}

export const ImagePreview = ({ src, onClick, onDelete }: ImagePreviewProps) => {
  const tGlobal = useTranslate()

  const enabled = typeof src === 'string' && !isUri(src)

  const { data } = useFileUrl(enabled ? src : '', {
    enabled,
  })

  const preview = data || getPreview(src)

  return (
    <Center
      pos="relative"
      bgColor={useColorModeValue('gray.100', 'gray.800')}
      rounded="lg"
      h={height}
      w={width}>
      {onDelete && (
        <IconButton
          size="sm"
          colorScheme="error"
          pos="absolute"
          aria-label="close"
          icon={<CloseIcon />}
          m={2}
          left={0}
          top={0}
          onClick={onDelete}
        />
      )}
      {onClick && (
        <Button colorScheme="primary" pos="absolute" onClick={onClick}>
          {preview ? tGlobal('update') : tGlobal('add')}
        </Button>
      )}
      {preview && (
        <Image
          flex={1}
          objectFit="cover"
          h={height}
          w={width}
          overflow="hidden"
          src={preview}
          rounded="lg"
        />
      )}
    </Center>
  )
}
