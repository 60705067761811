import { useTranslate } from '@/services/i18n'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

export type ConfirmModalProps = {
  onSubmit?: () => void
}

export const ConfirmModal = NiceModal.create(
  ({ onSubmit }: ConfirmModalProps) => {
    const modal = useModal()
    const tGlobal = useTranslate()

    return (
      <Modal isOpen={modal.visible} onClose={modal.hide}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{tGlobal('confirm')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>{tGlobal('confirmText')}</Text>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={3}>
              <Button
                colorScheme="warn"
                onClick={() => {
                  onSubmit?.()
                  modal.hide()
                }}>
                {tGlobal('submit')}
              </Button>
              <Button onClick={modal.hide}>{tGlobal('cancel')}</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  },
)
