import { keys } from 'ramda'
import { languages } from '../types'
import { translations } from '../translations'

export type Namespace = keyof typeof translations

export const resources = languages.reduce(
  (acc, lng) => ({
    ...acc,
    [lng]: keys(translations).reduce(
      (acc, key) => ({ ...acc, [key]: translations[key][lng] }),
      {},
    ),
  }),
  {},
)
