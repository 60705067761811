import {
  TabList,
  Tabs as ChakraTabs,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export type TabItem = {
  title: string
  content: ReactNode
}

export type TabsProps = {
  items: TabItem[]
}

export const Tabs = ({ items }: TabsProps) => {
  return (
    <ChakraTabs variant="soft-rounded" colorScheme="primary">
      <TabList gap={4}>
        {items.map(({ title }) => (
          <Tab key={title}>{title}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {items.map(({ title, content }) => (
          <TabPanel key={title}>{content}</TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  )
}
