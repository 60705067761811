import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { PropsWithChildren } from 'react'

export type UpdateModalprops = {
  title: string
}

export const UpdateModal = NiceModal.create(
  ({ title, children }: PropsWithChildren<UpdateModalprops>) => {
    const modal = useModal()

    return (
      <Modal isOpen={modal.visible} onClose={modal.hide} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          {children}
        </ModalContent>
      </Modal>
    )
  },
)
