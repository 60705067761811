import {
  FormTextAreaInputControlled,
  FormTextInputControlled,
} from '@/components'
import { useTranslate } from '@/services/i18n'
import { FormProvider, useForm } from 'react-hook-form'
import {
  ReviewFormValues,
  reviewFormServices as services,
} from './ReviewForm.services'
import { useEffect } from 'react'
import { Review } from '@/services/api/routes'
import { UpdateModalContent } from '@/components/Modals/UpdateModal/UpdateModalContent'
import { joiResolver } from '@hookform/resolvers/joi'
import { useManageReview } from '../../ReviewsTab.services'
import { UpdateModal } from '@/components/Modals/UpdateModal/UpdateModal'
import { useModal } from '@ebay/nice-modal-react'

const { getDefaultValues, schema } = services

export type ReviewFormProps = { review?: Review }

export const ReviewForm = ({ review }: ReviewFormProps) => {
  const t = useTranslate(undefined, { keyPrefix: 'admin.tabs.reviews.form' })
  const modal = useModal(UpdateModal)
  const { createAsync, isLoading, updateAsync } = useManageReview()

  const methods = useForm<ReviewFormValues>({
    defaultValues: getDefaultValues(review),
    mode: 'onSubmit',
    resolver: joiResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods

  useEffect(() => {
    reset(getDefaultValues(review))
  }, [reset, review])

  const onSubmit = async ({ id, ...d }: ReviewFormValues) => {
    if (id > -1) {
      await updateAsync({ id, ...d })
    } else {
      await createAsync([d])
    }
    modal.hide()
  }

  return (
    <FormProvider {...methods}>
      <UpdateModalContent
        isDisabled={!isDirty}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmit)}
        form={
          <>
            <FormTextInputControlled
              name="id"
              inputProps={{ type: 'hidden' }}
            />
            <FormTextInputControlled
              title={t('author')}
              name="author"
              isRequired
            />
            <FormTextAreaInputControlled
              title={t('content')}
              name="content"
              isRequired
            />
            <FormTextInputControlled
              title={t('note')}
              name="note"
              inputProps={{ type: 'number' }}
              isRequired
            />
          </>
        }
        onCancel={modal.hide}
      />
    </FormProvider>
  )
}
