import { UpdateModal } from '@/components/Modals/UpdateModal/UpdateModal'
import { useTranslate } from '@/services/i18n'
import {
  Button,
  HStack,
  IconButton,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { BiPencil, BiPlus, BiTrash } from 'react-icons/bi'
import { ServiceForm } from './components/ServiceForm/ServiceForm'
import { useServices } from '@/services/api/routes'
import { useModal } from '@ebay/nice-modal-react'
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal'
import { useFileUrlBis } from '@/services/api/utils/file'
import { useRemoveFiles } from '@/services/api/utils/file'
import { useManageService } from './ServicesTabServices'
import { Loader } from '@/designSystem'

export const ServicesTab = () => {
  const tGlobal = useTranslate()
  const t = useTranslate(undefined, { keyPrefix: 'admin.tabs.services' })

  const modal = useModal(UpdateModal)
  const confirmModal = useModal(ConfirmModal)

  const { mutateAsync: removeFiles } = useRemoveFiles()
  const fileUrl = useFileUrlBis()
  const { data: serviceData, isInitialLoading } = useServices()

  const { isRemoving, remove } = useManageService()

  if (isInitialLoading) {
    return <Loader />
  }

  return (
    <VStack align="stretch">
      <HStack justify="end">
        <Button
          colorScheme="primary"
          rightIcon={<BiPlus />}
          onClick={() =>
            modal.show({
              title: tGlobal('create'),
              children: <ServiceForm />,
            })
          }>
          {tGlobal('add')}
        </Button>
      </HStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t('table.order')}</Th>
              {process.env.REACT_APP_SITE_TYPE === 'MASSAGE' && (
                <>
                  <Th>{t('table.title')}</Th>
                  <Th>{t('table.description')}</Th>
                  <Th>{t('table.price')}</Th>
                </>
              )}
              <Th>{t('table.image')}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {serviceData
              ?.sort((a, b) => a.order - b.order)
              .map(service => (
                <Tr key={service.id}>
                  <Td whiteSpace="normal">{service.order}</Td>
                  {process.env.REACT_APP_SITE_TYPE === 'MASSAGE' && (
                    <>
                      <Td whiteSpace="normal">{service.title}</Td>
                      <Td maxW="500px" maxH="100px" whiteSpace="normal">
                        {service.description}
                      </Td>

                      <Td>{service.price}</Td>
                    </>
                  )}
                  <Td maxW="200px">
                    {service.image && <Image src={fileUrl(service.image)} />}
                  </Td>
                  <Td>
                    <HStack justify="end">
                      <IconButton
                        colorScheme="yellow"
                        aria-label="pencil"
                        icon={<BiPencil />}
                        onClick={() =>
                          modal.show({
                            title: tGlobal('update'),
                            children: <ServiceForm service={service} />,
                          })
                        }
                      />
                      <IconButton
                        colorScheme="red"
                        aria-label="trash"
                        isLoading={isRemoving}
                        icon={<BiTrash />}
                        onClick={() =>
                          confirmModal.show({
                            onSubmit: () => {
                              if (service.image) removeFiles([service.image])
                              remove({ id: service.id })
                            },
                          })
                        }
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}
