import Joi from 'joi'

export type LoginFormValues = {
  email: string
  password: string
}

const getDefaultValues = (): LoginFormValues => ({
  email: '',
  password: '',
})

const schema = Joi.object<LoginFormValues>({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string().required(),
})

export const loginServices = {
  getDefaultValues,
  schema,
}
