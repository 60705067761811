import { Text } from '@chakra-ui/react'
import { Trans, TransProps } from 'react-i18next'

const components = {
  strong: <Text as="span" fontWeight="bold" color="primary.500" />,
  italic: <Text as="span" fontStyle="italic" />,
}

export type TranslateTextProps = Pick<
  TransProps<any>,
  'i18nKey' | 't' | 'values'
>

export const TranslateText = (props: TranslateTextProps) => (
  <Text>
    <Trans components={components} {...props} />
  </Text>
)
