import { Button, ButtonGroup } from '@chakra-ui/react'
import {
  FormDatePickerInputControlled,
  FormTextAreaInputControlled,
  FormTextInputControlled,
  RecatpchaBadge,
} from '@/components'
import { FormProvider, useForm } from 'react-hook-form'
import {
  BookingFormValues,
  bookingFormServices as services,
} from './BookingForm.services'
import { EmailIcon } from '@chakra-ui/icons'
import { useTranslate } from '@/services/i18n'
import { joiResolver } from '@hookform/resolvers/joi'
import { useHandleSuccess } from '@/hooks/useHandleSuccess'
import { useHandleError } from '@/hooks/useHandleError'
import { useGeneral } from '@/services/api/routes/general'
import { useSendEmail } from '@/services/api/routes/sendMail'
import { format } from 'date-fns'

const { getDefaultValues, schema } = services

export const BookingForm = () => {
  const t = useTranslate(undefined, { keyPrefix: 'booking.form' })
  const { data: generalData } = useGeneral()
  const { mutateAsync: sendEmail, isLoading } = useSendEmail({
    onError: () => handleError(),
    onSuccess: () => {
      handleSuccess({ title: t('messageSent') })
      reset()
    },
  })

  const handleSuccess = useHandleSuccess()
  const handleError = useHandleError()

  const tGlobal = useTranslate()
  const methods = useForm<BookingFormValues>({
    defaultValues: getDefaultValues(),
    mode: 'onSubmit',
    resolver: joiResolver(schema),
  })

  const { handleSubmit, reset } = methods

  const onSubmit = async ({ date, ...data }: BookingFormValues) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(process.env.REACT_APP_GOOGLE_SITEKEY, { action: 'mailer' })
        .then(captcha => {
          sendEmail({
            to: [
              {
                email: generalData?.[0].contactEmail || '',
              },
            ],
            bookInfo: {
              ...data,
              date: date ? format(date, 'dd/MM/yyyy') : undefined,
            },
            captcha,
          })
        })
    })
  }

  return (
    <FormProvider {...methods}>
      <FormTextInputControlled title={t('name')} name="name" isRequired />
      <FormTextInputControlled title={t('email')} name="email" isRequired />
      <FormTextInputControlled
        title={t('cellphone')}
        name="cellphone"
        isRequired
      />
      <FormDatePickerInputControlled title={t('date')} name="date" isRequired />
      <FormTextAreaInputControlled title={t('note')} name="note" isRequired />
      <RecatpchaBadge />
      <ButtonGroup>
        <Button
          variant="solid"
          colorScheme="primary"
          leftIcon={<EmailIcon />}
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit)}>
          {tGlobal('send')}
        </Button>
      </ButtonGroup>
    </FormProvider>
  )
}
