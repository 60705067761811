import { ImageFile } from '@/designSystem'
import { Service } from '@/services/api/routes'
import Joi from 'joi'

export type ServiceFormValues = Omit<Service, 'image'> & {
  oldImage: string
  image: ImageFile | null
}

const getDefaultValues = (service?: Service): ServiceFormValues => ({
  id: service?.id || -1,
  oldImage: service?.image || '',
  image: null,
  order: service?.order || 0,
  ...(process.env.REACT_APP_SITE_TYPE === 'MASSAGE' && {
    title: service?.title || '',
    description: service?.description || '',
    price: service?.price || 0,
  }),
})

const schema = Joi.object<ServiceFormValues>({
  id: Joi.number(),
  oldImage: Joi.string().optional().allow(''),
  image: Joi.object<ImageFile>({
    name: Joi.string(),
    data: Joi.any(),
    content: Joi.string(),
  })
    .optional()
    .allow(null),
  order: Joi.number().required(),
  ...(process.env.REACT_APP_SITE_TYPE === 'MASSAGE' && {
    title: Joi.string().required(),
    description: Joi.string().required(),
    price: Joi.number().required().min(1),
  }),
})

export const serviceFormServices = {
  getDefaultValues,
  schema,
}
