import { PropsWithChildren } from 'react'
import { Box } from '@chakra-ui/react'
import Slider, { Settings } from 'react-slick'

// Settings for the slider
const settings: Settings = {
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
}

type CarouselProps = {
  image?: string
}
export const Carousel = ({
  image,
  children,
}: PropsWithChildren<CarouselProps>) => {
  // const [slider, setSlider] = useState<Slider | null>(null)

  return (
    <Box
      position="relative"
      width="full"
      overflow="hidden"
      bgPos="center"
      bgSize="cover"
      bgImage={`url(${image})`}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      {/* Slider */}
      <Slider {...settings} /*ref={slider => setSlider(slider)}*/>
        {children}
      </Slider>
    </Box>
  )
}
