import { InputProps, useColorModeValue } from '@chakra-ui/react'
import { FormContainer } from '../FormContainer/FormContainer'
import { FormContainerProps } from '../FormContainer/FormContainer'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { SingleDatepickerProps } from 'chakra-dayzed-datepicker'

export type FormDatePickerInputProps = FormContainerProps & {
  datePickerProps: Omit<SingleDatepickerProps, 'propsConfigs'>
  inputProps?: InputProps
}

export const FormDatePickerInput = ({
  datePickerProps,
  inputProps,
  ...props
}: FormDatePickerInputProps) => (
  <FormContainer {...props}>
    <SingleDatepicker
      {...datePickerProps}
      propsConfigs={{
        inputProps,
        dayOfMonthBtnProps: {
          todayBtnProps: {
            fontWeight: 'extrabold',
            color: 'white',
            bgColor: useColorModeValue('gray.400', 'gray.600'),
          },
          defaultBtnProps: {
            _hover: {
              bgColor: useColorModeValue('primary.100', 'primary.800'),
            },
          },
          selectedBtnProps: {
            bgColor: 'primary.500',
            color: 'white',
          },
        },
      }}
    />
  </FormContainer>
)
