import { useToast } from '@chakra-ui/react'

type HandleSuccessProps = {
  title: string
}

export const useHandleSuccess = () => {
  const toast = useToast()

  return ({ title }: HandleSuccessProps) =>
    toast({ colorScheme: 'success', title, status: 'success' })
}
