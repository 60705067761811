import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  HStack,
  Container,
  useColorMode,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
} from '@chakra-ui/icons'
import { forwardRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { BiCog, BiLogInCircle, BiLogOutCircle } from 'react-icons/bi'
import { useAuth } from '@/components/AuthProvider/AuthProvider'
import { routes } from '@/navigation'

export type NavItem = {
  label: string
  subLabel?: string
  children?: Array<NavItem>
  href: string
}

export type NavbarProps = {
  title: string
  navItems: NavItem[]
  homeHref: string
}

export const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  ({ title, navItems, homeHref }, ref) => {
    const { colorMode, toggleColorMode } = useColorMode()
    const { isOpen, onToggle } = useDisclosure()

    const { auth, signOut } = useAuth()
    const [isLoadingSignOut, setIsLoadingSignOut] = useState(false)
    const navigate = useNavigate()

    const handleSignOut = async () => {
      setIsLoadingSignOut(true)
      try {
        await signOut()
      } finally {
        setIsLoadingSignOut(false)
      }
    }

    return (
      <Box ref={ref}>
        <Flex
          bg={useColorModeValue('gray.50', 'gray.900')}
          color={useColorModeValue('gray.600', 'white')}
          minH="60px"
          py={4}
          align="center">
          <Container maxW="container.lg">
            <HStack justify="space-between">
              <HStack>
                <Flex display={{ base: 'flex', md: 'none' }}>
                  <IconButton
                    onClick={onToggle}
                    icon={
                      isOpen ? (
                        <CloseIcon w={3} h={3} />
                      ) : (
                        <HamburgerIcon w={5} h={5} />
                      )
                    }
                    color="primary.500"
                    aria-label="Toggle Navigation"
                  />
                </Flex>
                <Flex flex={1} justify={{ base: 'end', md: 'start' }}>
                  <Text
                    textAlign={useBreakpointValue({
                      base: 'center',
                      md: 'left',
                    })}
                    fontFamily="heading"
                    color={useColorModeValue('gray.800', 'white')}
                    as={NavLink}
                    to={homeHref}>
                    {title}
                  </Text>

                  <Flex
                    display={{ base: 'none', md: 'flex' }}
                    ml={10}
                    justify="end"
                    flex={1}>
                    <DesktopNav navItems={navItems} />
                  </Flex>
                </Flex>
              </HStack>
              <HStack>
                <IconButton
                  aria-label="Toggle color mode"
                  icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                  onClick={toggleColorMode}
                  variant="ghost"
                />
                {auth && (
                  <IconButton
                    aria-label="Go to admin panel"
                    icon={<BiCog />}
                    onClick={() => navigate(routes.admin)}
                    variant="ghost"
                  />
                )}
                <IconButton
                  aria-label="Login logout"
                  icon={auth ? <BiLogOutCircle /> : <BiLogInCircle />}
                  onClick={auth ? handleSignOut : () => navigate(routes.login)}
                  isLoading={isLoadingSignOut}
                  variant="ghost"
                />
              </HStack>
            </HStack>
          </Container>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav navItems={navItems} />
        </Collapse>
      </Box>
    )
  },
)

type SharedNavProps = {
  navItems: NavItem[]
}

const DesktopNav = ({ navItems }: SharedNavProps) => {
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')

  return (
    <HStack spacing={4}>
      {navItems.map(({ label, href, children }) => (
        <Box key={label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Box
                as={NavLink}
                _activeLink={{ color: 'primary.500' }}
                to={href}
                p={2}
                fontSize="sm"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: 'primary.600',
                }}>
                {label}
              </Box>
            </PopoverTrigger>

            {children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm">
                <Stack>
                  {children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </HStack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as={NavLink}
      to={href}
      role="group"
      display="block"
      p={2}
      rounded="md"
      _hover={{ bg: 'primary.50' }}>
      <HStack alignItems="center" justifyContent="center" cursor="pointer">
        <Box>
          <Text
            transition="all .3s ease"
            _groupHover={{ color: 'primary.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition="all .3s ease"
          transform="translateX(-10px)"
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify="flex-end"
          align="center"
          flex={1}>
          <Icon color="primary.400" w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </HStack>
    </Box>
  )
}

const MobileNav = ({ navItems }: SharedNavProps) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {navItems.map(item => (
        <MobileNavItem key={item.label} {...item} />
      ))}
    </Stack>
  )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Stack spacing={4} onClick={children && onToggle} cursor="pointer">
      <Box
        py={2}
        as={NavLink}
        _activeLink={{ color: 'primary.500' }}
        fontWeight={600}
        to={href}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
          color: 'primary.600',
        }}>
        {label}
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align="start">
          {children &&
            children.map(({ label, href }) => (
              <Box as={NavLink} key={label} py={2} to={href}>
                {label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}
