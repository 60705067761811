import { extendTheme } from '@chakra-ui/react'
import { theme as chakraTheme } from '@chakra-ui/theme'

export const theme = extendTheme({
  colors: {
    error: chakraTheme.colors.red,
    warn: chakraTheme.colors.yellow,
    success: chakraTheme.colors.green,
    // @ts-ignore
    primary: chakraTheme.colors[process.env.REACT_APP_PRIMARY_COLOR],
    secondary: chakraTheme.colors.gray,
  },
})
