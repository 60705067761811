import {
  useQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
} from '@supabase-cache-helpers/postgrest-react-query'
import { supabase } from '../..'
import { Review } from './entities'
import { MutationOptions, QueryOptions } from '../../types'

const queryCol = 'id, author, content, note'
const mutationCol = 'author, content, note'

export const useReviews = (opt?: QueryOptions) =>
  useQuery<Review[]>(
    supabase
      .from('reviews')
      .select(queryCol)
      .eq('siteId', process.env.REACT_APP_SITE_ID),
    opt,
  )

export const useReview = (id?: number, opt?: QueryOptions) =>
  useQuery<Review>(supabase.from('reviews').select(queryCol).eq('id', id), opt)

export const useAddReviews = (opt?: MutationOptions<Review[]>) =>
  useInsertMutation(supabase.from('reviews'), ['id'], mutationCol, opt)

export const useUpdateReview = (opt?: MutationOptions<Review>) =>
  useUpdateMutation(supabase.from('reviews'), ['id'], queryCol, opt)

export const useDeleteReview = (opt?: MutationOptions<Review>) =>
  useDeleteMutation(supabase.from('reviews'), ['id'], mutationCol, opt)
