import { FormFileInput, FormFileInputProps } from '@/designSystem'
import { useController, useFormContext } from 'react-hook-form'

export type FormFileInputControlledProps = {
  name: string
  oldName: string
} & Partial<Omit<FormFileInputProps, 'error'>>

export const FormFileInputControlled = ({
  name,
  oldName,
  ...props
}: FormFileInputControlledProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name })

  const { getValues, setError, clearErrors } = useFormContext()

  const oldValue = getValues(oldName)

  return (
    <FormFileInput
      error={error}
      onChange={i => {
        clearErrors(name)
        onChange(i)
      }}
      onError={type => setError(name, { type })}
      value={value}
      oldValue={oldValue}
      {...props}
    />
  )
}
