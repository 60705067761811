import { UpdateModal } from '@/components/Modals/UpdateModal/UpdateModal'
import { useTranslate } from '@/services/i18n'
import {
  Button,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { BiPencil, BiPlus, BiTrash } from 'react-icons/bi'
import { useReviews } from '@/services/api/routes'
import { useModal } from '@ebay/nice-modal-react'
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal'
import { ReviewForm } from './components/ReviewForm/ReviewForm'
import { useManageReview } from './ReviewsTab.services'
import { Loader } from '@/designSystem'

export const ReviewsTab = () => {
  const tGlobal = useTranslate()
  const t = useTranslate(undefined, { keyPrefix: 'admin.tabs.reviews' })
  const { remove, isRemoving } = useManageReview()

  const { data: reviewData, isInitialLoading } = useReviews()

  const modal = useModal(UpdateModal)
  const confirmModal = useModal(ConfirmModal)

  if (isInitialLoading) {
    return <Loader />
  }

  return (
    <VStack align="stretch">
      <HStack justify="end">
        <Button
          colorScheme="primary"
          rightIcon={<BiPlus />}
          onClick={() =>
            modal.show({
              title: tGlobal('create'),
              children: <ReviewForm />,
            })
          }>
          {tGlobal('add')}
        </Button>
      </HStack>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t('table.author')}</Th>
              <Th>{t('table.content')}</Th>
              <Th>{t('table.note')}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {reviewData?.map(review => (
              <Tr key={review.id}>
                <Td>{review.author}</Td>
                <Td maxW="500px" maxH="100px" whiteSpace="normal">
                  {review.content}
                </Td>
                <Td>{review.note}</Td>

                <Td>
                  <HStack justify="end">
                    <IconButton
                      colorScheme="yellow"
                      aria-label="pencil"
                      icon={<BiPencil />}
                      onClick={() =>
                        modal.show({
                          title: tGlobal('update'),
                          children: <ReviewForm review={review} />,
                        })
                      }
                    />
                    <IconButton
                      colorScheme="red"
                      aria-label="trash"
                      isLoading={isRemoving}
                      icon={<BiTrash />}
                      onClick={() =>
                        confirmModal.show({
                          onSubmit: () => remove({ id: review.id }),
                        })
                      }
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}
