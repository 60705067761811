import { Box, Center, Text, useColorModeValue } from '@chakra-ui/react'

export const Footer = () => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Center w="full" p={4}>
        <Text>© {new Date().getFullYear()} Codewhile. All rights reserved</Text>
      </Center>
    </Box>
  )
}
