import { Carousel, Stars } from '@/designSystem'
import { useReviews } from '@/services/api/routes'
import { useGeneral } from '@/services/api/routes/general'
import { useFileUrl } from '@/services/api/utils/file'
import {
  Box,
  Container,
  Heading,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { shuffle } from 'remeda'

export const TestimonialsCarousel = () => {
  const { data: reviews, isLoading } = useReviews()
  const shuffledReviews = useMemo(() => shuffle(reviews || []), [reviews])

  const { data } = useGeneral()
  const generalData = data?.[0]

  const { data: image } = useFileUrl(generalData?.reviewImage || '', {
    enabled: !!generalData?.reviewImage,
  })

  if (isLoading) return <Spinner />

  return (
    <Carousel image={image}>
      {shuffledReviews?.map(rev => (
        <TestimonialItem key={rev.author} {...rev} />
      ))}
    </Carousel>
  )
}

type TestimonialItemProps = {
  author: string
  content: string
  note: number
}

const TestimonialItem = ({ author, content, note }: TestimonialItemProps) => (
  <Box height="50vh" position="relative">
    <Container
      height="50vh"
      position="relative"
      maxW="container.lg"
      justifyContent="center"
      alignItems="center">
      <VStack
        spacing={2}
        w="full"
        position="relative"
        top="50%"
        transform="translate(0, -50%)"
        p={{ base: 5, lg: 10 }}
        rounded="2xl"
        boxShadow="2xl"
        bgColor={useColorModeValue('gray.50', 'gray.900')}>
        <Stars note={note} />
        <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
          {author}
        </Heading>
        <Text
          textAlign="center"
          fontSize={{ base: 'md', lg: 'lg' }}
          color="gray.500">
          {content}
        </Text>
      </VStack>
    </Container>
  </Box>
)
