import { FormDatePickerInput, FormDatePickerInputProps } from '@/designSystem'
import { useController } from 'react-hook-form'

export type FormDatePickerInputControlledProps = {
  name: string
} & Partial<Omit<FormDatePickerInputProps, 'error'>>

export const FormDatePickerInputControlled = ({
  name,
  datePickerProps,
  ...props
}: FormDatePickerInputControlledProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name })

  return (
    <FormDatePickerInput
      {...props}
      datePickerProps={{
        ...datePickerProps,
        name: 'date-picker',
        onDateChange: e => {
          onChange(e)
        },
        date: value,
      }}
      error={error}
    />
  )
}
