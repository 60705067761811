import { useTranslate } from '@/services/i18n'
import {
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  VStack,
} from '@chakra-ui/react'
import { ReactElement } from 'react'

export type UpdateModalContentProps = {
  form: ReactElement
  isLoading: boolean
  isDisabled: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const UpdateModalContent = ({
  onSubmit,
  onCancel,
  form,
  isDisabled,
  isLoading,
}: UpdateModalContentProps) => {
  const tGlobal = useTranslate()

  return (
    <>
      <ModalBody pb={6}>
        <VStack align="stretch">{form}</VStack>
      </ModalBody>

      <ModalFooter>
        <HStack spacing={3}>
          <Button
            isDisabled={isDisabled}
            isLoading={isLoading}
            colorScheme="primary"
            onClick={onSubmit}>
            {tGlobal('submit')}
          </Button>
          <Button onClick={onCancel}>{tGlobal('cancel')}</Button>
        </HStack>
      </ModalFooter>
    </>
  )
}
