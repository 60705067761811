import {
  Center,
  CenterProps,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  PropsWithChildren,
  ReactElement,
  forwardRef,
  isValidElement,
} from 'react'
import Slider, { Settings } from 'react-slick'

const settings: Settings = {
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
}

type SectionProps = {
  images?: string[]
  title?: string
  subText?: ReactElement | string
} & CenterProps

export const Section = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SectionProps>
>(
  (
    {
      images,
      children,
      title,
      subText,
      ...props
    }: PropsWithChildren<SectionProps>,
    ref,
  ) => {
    return (
      <Center {...props} ref={ref} w="full" pos="relative">
        {images?.length && (
          <Slider {...settings} className="image-slider">
            {images?.map(url => (
              <Center
                {...props}
                ref={ref}
                w="full"
                bgPos="center"
                bgSize="cover"
                bgImage={`url(${url})`}
              />
            ))}
          </Slider>
        )}

        <Container
          maxW="container.lg"
          pos={images?.length ? 'absolute' : 'initial'}>
          <VStack spacing={12} align="stretch">
            {title && <Heading>{title}</Heading>}
            {subText &&
              (isValidElement(subText) ? subText : <Text>{subText}</Text>)}
            {children}
          </VStack>
        </Container>
      </Center>
    )
  },
)
