import { useHandleError } from '@/hooks/useHandleError'
import { useHandleSuccess } from '@/hooks/useHandleSuccess'
import {
  useAddReviews,
  useDeleteReview,
  useUpdateReview,
} from '@/services/api/routes'
import { useTranslate } from '@/services/i18n'

export const useManageReview = () => {
  const tGlobal = useTranslate(undefined, { keyPrefix: 'toasts' })
  const handleSuccess = useHandleSuccess()
  const handleError = useHandleError()

  const { mutateAsync: createAsync, isLoading: isCreating } = useAddReviews({
    onSuccess: () => handleSuccess({ title: tGlobal('created') }),
    onError: () => handleError(),
  })
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdateReview({
    onSuccess: () => handleSuccess({ title: tGlobal('edited') }),
    onError: () => handleError(),
  })
  const { mutate: remove, isLoading: isRemoving } = useDeleteReview({
    onSuccess: () => handleSuccess({ title: tGlobal('deleted') }),
    onError: () => handleError(),
  })

  return {
    createAsync: (d: any[]) =>
      createAsync([{ siteId: process.env.REACT_APP_SITE_ID, ...d[0] }]),
    updateAsync,
    remove,
    isLoading: isCreating || isUpdating,
    isRemoving,
  }
}
