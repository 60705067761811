import { FormFileManyInput, FormFileManyInputProps } from '@/designSystem'
import { useController, useFormContext } from 'react-hook-form'

export type FormFileManyInputControlledProps = {
  name: string
  oldName: string
  deletedName: string
} & Partial<Omit<FormFileManyInputProps, 'error'>> &
  Pick<FormFileManyInputProps, 'directoryPath'>

export const FormFileManyInputControlled = ({
  name,
  oldName,
  deletedName,
  ...props
}: FormFileManyInputControlledProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name })

  const { watch, setError, clearErrors, setValue } = useFormContext()

  const oldValue = watch(oldName)
  const deletedDldValue = watch(deletedName) || []

  return (
    <FormFileManyInput
      error={error}
      onChange={v => {
        clearErrors(name)
        onChange(v)
      }}
      onChangeOldValue={v => setValue(oldName, v)}
      onDeleteOldValue={v =>
        setValue(deletedName, [...deletedDldValue, v], { shouldDirty: true })
      }
      onError={type => setError(name, { type })}
      value={value}
      oldValue={oldValue}
      {...props}
    />
  )
}
