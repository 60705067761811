import { useRemoveFiles, useUpload } from '@/services/api/utils/file'

export const useHandleFileUpload = () => {
  const { mutateAsync: uploadAsync, isLoading: isLoadingUpload } = useUpload()
  const { mutateAsync: removeAsync, isLoading: isLoadingRemove } =
    useRemoveFiles()

  return {
    uploadAsync,
    removeAsync,
    isLoading: isLoadingUpload || isLoadingRemove,
  }
}
